import React, { useRef, useState, useEffect } from 'react'

//components
import PhotoGallery from '../../../components/PhotoGallery'

import { isElementInViewport } from '../../../components/functions'

//assetes
import bg from '../../../assets/images/brands/our-story/brighter-bg.png'

export const Gallery = ({ slice }) => {
    const [isInView, setIsInView] = useState(false)
    const ref = useRef(null)

    const data = slice.primary

    //create an event listener
    useEffect(() => {
        if(isElementInViewport(ref.current)){
            setIsInView(isElementInViewport(ref.current))
        }
        window.addEventListener('scroll',() => {
            if(isElementInViewport(ref.current)){
                setIsInView(true)
            }
        })

        //Adding instagram script tag
        const script = document.createElement('script')
        script.src = "https://apps.elfsight.com/p/platform.js";
        script.async = true;
        document.body.appendChild(script);
        if(script.readyState){ // only required for IE <9
            script.onreadystatechange = function(){
                if(script.readyState === "loaded" || script.readyState === "complete"){
                    script.onreadystatechange = null;
                    loadedSocialPost();
                }
            }
        }else{
            script.onload = function(){
                loadedSocialPost();
            }
        }

        const loadedSocialPost = () => {
            //Data layer - social post clicked
            var observer = new MutationObserver(function(mutations){
                var hasUpdated = false;
                for(var idx=0; idx < mutations.length; idx++){
                    var mutation = mutations[idx];

                    if(mutation.type === 'childList' && mutation.addedNodes.length){
                        hasUpdated = true;
                        break;
                    }
                }

                if(hasUpdated){ //Check instagram feed is being updated
                    var eachFeed = document.querySelector('.instagram-feed-wrapper').querySelectorAll('.eapps-instagram-feed-posts-item-overlay');
                    if(eachFeed.length > 0){ //Check if instagram posts are loaded
                        for(let i=0; i < eachFeed.length; i++){
                            eachFeed[i].addEventListener('click', clickSocialPost);
                        }
                    }
                }
            })

            if(document.querySelector('.instagram-feed-wrapper')){
                observer.observe(document.querySelector('.instagram-feed-wrapper'), {
                    attributes: false,
                    childList: true,
                    characterData: false,
                    subtree: true
                })
            }
        }

        const clickSocialPost = (e) => {
            var currentPost = e.target.closest('.eapps-instagram-feed-posts-item-template-tile');
            var currentPostCode = currentPost.dataset.code;
            var allPosts = document.querySelector('.instagram-feed-wrapper').querySelectorAll('.eapps-instagram-feed-posts-item-template-tile');
            for(let idx=0; idx < allPosts.length; idx++){
                if(allPosts[idx].dataset.code === currentPostCode){
                    var feedLink = e.target.closest('.eapps-instagram-feed-posts-item-link').getAttribute('href');
                    feedLink = feedLink.replace('https://www.instagram.com/p', "")
                    let dl = {
                        'event': 'social_post_clicked',
                        'outbound_link': `${feedLink}`,
                        'tile_position': (idx + 1)
                    }
                    clickDatalayer(dl);
                }
            }
        }

        const clickDatalayer = (data) => {
            //Data layer - Social post clicked
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push(data)
        }

        return () => {
            document.body.removeChild(script);
        }
    }, [])

    //If no instagram set up & no gallery item, disable the section
    if(!data.display_instagram && (slice.items === undefined || slice.items.length === 0)){
        return(<></>)
    }

    return(
        <section
            className={(isInView)? 'social-media-gallery d-flex align-items-center in-view py-5 py-lg-0' : 'social-media-gallery d-flex align-items-center py-5 py-lg-0'}
            ref={ref}
            style={{backgroundImage: `url(${bg})`}}
        >
            <div className='w-100'>
                <div className='container mb-3'>
                    <h3 className='d-flex align-items-center position-relative'>
                        {(data.display_instagram)?
                            <span>FOLLOW US ON INSTAGRAM</span>
                            :
                            <span>GALLERY</span>
                        }
                    </h3>
                </div>
                {/* Gallery or instagram here */}
                <div className="gallery-wrapper mt-5 slide-up">
                    {(data.display_instagram)?
                        <div className='social-media-insta-bg'>
                            <div className="elfsight-app-3329c000-f437-48a6-a0ad-67baeec4d281 instagram-feed-wrapper"></div>
                        </div>
                        :
                        <div className='social-media-gallery-bg fade-in animation-delay-2'>
                            <PhotoGallery data={slice.items} />
                        </div>
                    }
                </div>
            </div>
        </section>
    )
}

