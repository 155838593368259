import { OurWineMakersNote } from "./OurWineMakersNote"
import { Gallery } from "./Gallery";
import { WhatOthersSay } from "./WhatOthersSay"
import { Products } from "../our-story/Products";
import { ProductDetails } from "../our-story/ProductDetails";

export const components = {
  our_wine_makers_note: OurWineMakersNote,
  instagram__or_gallery_: Gallery,
  what_others_say: WhatOthersSay,
  products: Products,
  product_details: ProductDetails
}